import React, { useContext, useEffect, useRef, useState } from 'react';
import Map from '@components/Map';
import { OtherUtilityData } from '@components/Map/OtherUtilities';
import { Container, MapWrapper } from './styles';
import Header from './Header';
import AnalyzedEntityContext, { AnalyzedEntity } from '@contexts/AnalyzedEntityContext';
import { useMapControlsStore } from '../store';
import { useGetInterpolationData } from '@hooks/prefetch/interpolation';
import { useYPeaks } from '@hooks/prefetch/useYPeaks';
import { useScanPath } from '@hooks/prefetch/useScanPath';
import { useFixedScanPath } from '@hooks/prefetch/useFixedScanPath';
import { useUtilityData } from '@hooks/prefetch/useUtilityData';
import { usePipesCrossings } from '@hooks/prefetch/usePipesCrossings';
import { useMapLoaderStore } from '@store';
import { MapLoaderIndicator } from '@components/Map/MpaLoaderIndicator/MapLoaderIndicator';
import { useZoomLevel } from '@hooks/useZoomLevel';

const centerLat = 40.820410399000004;
const centerLng = -72.957328127;

interface Props {
  isOrthophotoLayerOn: boolean;
  isInterpolationLayerOn: boolean;
  isScanPathLayerOn: boolean;
  isUtilitiesLayerOn: boolean;
  isCrossingIndex: boolean;
  isYPeaks: boolean;
  isFixedScanPath: boolean;
  otherUtilitiesData?: OtherUtilityData[];
  interpolationType?: string;
  analyzedEntity: AnalyzedEntity;
  analyzedEntityList: AnalyzedEntity[];
}

const MapView: React.FC<Props> = (props) => {
  const {
    isOrthophotoLayerOn,
    isUtilitiesLayerOn,
    isInterpolationLayerOn,
    isScanPathLayerOn,
    otherUtilitiesData,
    isCrossingIndex,
    isYPeaks,
    interpolationType,
    analyzedEntity,
    analyzedEntityList,
    isFixedScanPath
  } = props;

  const interpolationData = useGetInterpolationData(
    { analyzedEntityList, analyzedEntity, interpolationType },
    [analyzedEntity?.id, interpolationType, analyzedEntityList.length]
  )?.read();

  const yPeaks = useYPeaks(analyzedEntity, [analyzedEntity?.scan_frequency?.y_peaks_view]);
  const scanPathData = useScanPath(analyzedEntity, [analyzedEntity?.scan?.scan_path_url]);
  const fixedScanPathData = useFixedScanPath(analyzedEntity, [
    analyzedEntity?.scan?.scan_path_fix_mode_view
  ]);
  const utilityDataJson = useUtilityData(analyzedEntity, [analyzedEntity?.geometry]);
  const pipesCrossings = usePipesCrossings(analyzedEntity, [analyzedEntity?.pipe_crossings]);
  const loaders = useMapLoaderStore((state) => state.loaders);

  const mapRef = useRef<L.Map | null>(null);

  const { zoomLevel } = useZoomLevel();
  const { maxZoom, minZoom } = useMapControlsStore();

  const zoomToCenter = (): void => {
    if (!utilityDataJson) {
      return;
    }

    if (!mapRef.current) {
      return;
    }

    const coordinates = utilityDataJson.coordinates[0];
    if (!coordinates) {
      return;
    }
    mapRef.current.setView([coordinates[1], coordinates[0]], zoomLevel);
  };

  useEffect(() => {
    utilityDataJson && zoomToCenter();
  }, [utilityDataJson]);

  return (
    <Container>
      <Header onClickCenter={zoomToCenter} />
      <MapWrapper>
        {!!Object.keys(loaders).length && <MapLoaderIndicator loaders={loaders} />}
        <Map
          mapRef={mapRef}
          isCrossingIndex={isCrossingIndex}
          isScanPathLayerOn={isScanPathLayerOn}
          isOrthophotoLayerOn={isOrthophotoLayerOn}
          isUtilitiesLayerOn={isUtilitiesLayerOn}
          isInterpolationLayerOn={isInterpolationLayerOn}
          isYPeaks={isYPeaks}
          yPeaks={yPeaks}
          interpolationData={interpolationData}
          scanPathData={isFixedScanPath ? fixedScanPathData : scanPathData}
          utilityData={utilityDataJson}
          otherUtilitiesData={otherUtilitiesData}
          center={[centerLat, centerLng]}
          maxInterpolation={maxZoom / 100}
          minInterpolation={minZoom / 100}
          pipesCrossing={pipesCrossings}
        />
      </MapWrapper>
    </Container>
  );
};

export default MapView;
