import React, { lazy, useContext, useEffect, useMemo, useState } from 'react';
import NavigationBar from '@components/NavigationBar';
import {
  Container,
  InnerContainer,
  MapContainer,
  PageContainer,
  Section
} from '@pages/Main/styles';
import Header from '@pages/Main/Header';
import ControlButtons from '@pages/Main/ControlButtons';
import { AnalyzedEntity, Status, useUpdateAnalyzedEntityByIdMutation } from '@graphql/generated';
import { useEffectAsync } from '@hooks/useEffectAsync';
import {
  getAnalyzedEntitiesFromScans,
  getFilterFormURL,
  getSortedAnalyzedEntities
} from '@helpers/filterData';
import NavigationBarContent from '@pages/Main/NavigationBarContent';
import { useAllScansByFilterWithCountriesQuery } from '@hooks/useAllScansByFilterWithCountriesQuery';
import { Option } from '@components/SelectInput';
import { useUrlParams } from '@hooks/useUrlParams';
import { useAnalyzedEntity } from '@hooks/prefetch/useAnalyzedEntity';
import { OtherUtilityData } from '@components/Map/OtherUtilities';

import AnalyzedEntityContext from '@contexts/AnalyzedEntityContext';
import SortContext from '@contexts/SortContext';
import { useInterpolationOptions } from '@components/Map/Interpolation/useInterpolationOptions';

import MapView from '@pages/Main/MapView';
import { useDebounceFunction } from '@hooks/useDebounceFunction';
import UserContext from '@contexts/UserContext';
import { usePolygonStore } from '@store';

const DetailsView = lazy(() => import('@pages/Main/DetailsView'));

const InterpolationTypeUrlParamKey = 'interpolationType';

const Main: React.FC = () => {
  const [isOrthophotoLayerOn, setIsOrthophotoLayerOn] = useState(true);
  const [isScanPathLayerOn, setIsScanPathLayerOn] = useState(true);
  const [isUtilitiesLayerOn, setIsUtilitiesLayerOn] = useState(true);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isCrossingIndex, setIsCrossingIndex] = useState(true);
  const [isYPeaks, setIsYPeaks] = useState(false);
  const [isFixedScanPath, setIsFixedScanPath] = useState(false);

  const [selectedInterpolationId, setSelectedInterpolationId] = useState<Option['key']>('');
  const [updateAnalyzedEntityById] = useUpdateAnalyzedEntityByIdMutation();
  const filter = getFilterFormURL();
  const { scans, getScans, loading } = useAllScansByFilterWithCountriesQuery();
  const { setUrlParam, getUrlParam } = useUrlParams();
  const [pinned, setPinned] = useState(false);
  const currentId = getUrlParam('currentId') ?? '';

  const { sortBy } = useContext(SortContext);
  const {
    analyzedEntity,
    analyzedEntityList,
    setAnalyzedEntity,
    setAnalyzedEntityList,
    setStatuses,
    statuses,
    setFlagged,
    flagged,
    emptyAnalyzedEntitiesIdList
  } = useContext(AnalyzedEntityContext);
  const { user } = useContext(UserContext);

  const [isFlagged, setIsFlagged] = useState<boolean>(analyzedEntity?.flagged || false);

  const analyzedEntities = getAnalyzedEntitiesFromScans(scans);
  const sortedAnalyzedEntities = getSortedAnalyzedEntities(analyzedEntityList, sortBy);
  const interpolationOptions = useInterpolationOptions(analyzedEntity);
  const currentAnalyzedEntityt = useMemo(
    () => sortedAnalyzedEntities.find((s) => s.id === currentId) as AnalyzedEntity,
    [sortedAnalyzedEntities]
  );
  const interpolationTypeFromURL = getUrlParam(InterpolationTypeUrlParamKey);
  const ids = sortedAnalyzedEntities.map((item) => item.id);

  const idsForFetch = filter.analyzedEntitiesIds.length
    ? filter.analyzedEntitiesIds
    : analyzedEntities.map((item) => item.id);

  const setStatusLocally = (status: Status): void => {
    if (analyzedEntity?.status?.toLocaleLowerCase() === Status.Pending.toLocaleLowerCase()) {
      setStatuses({
        ...statuses,
        [status.toLowerCase()]: statuses[status.toLowerCase() as keyof typeof statuses] + 1,
        pending: statuses.pending - 1
      });
    }
    if (
      analyzedEntity?.status?.toLocaleLowerCase() === Status.Rejected.toLocaleLowerCase() &&
      status.toLocaleLowerCase() === Status.Approved.toLocaleLowerCase()
    ) {
      setStatuses({
        ...statuses,
        approved: statuses.approved + 1,
        rejected: statuses.rejected - 1
      });
    } else if (
      analyzedEntity?.status?.toLocaleLowerCase() === Status.Approved.toLocaleLowerCase() &&
      status.toLocaleLowerCase() === Status.Rejected.toLocaleLowerCase()
    ) {
      setStatuses({
        ...statuses,
        approved: statuses.approved - 1,
        rejected: statuses.rejected + 1
      });
    }

    const newAnalyzedEntity = { ...analyzedEntity, status };

    const newAnalyzedEntityList = [...analyzedEntityList];
    const analyzedEntityIndex = newAnalyzedEntityList.findIndex((item) => item.id === currentId);
    newAnalyzedEntityList[analyzedEntityIndex] = {
      ...newAnalyzedEntity,
      id: newAnalyzedEntity.id!
    };

    setAnalyzedEntity(newAnalyzedEntity as AnalyzedEntity);
    setAnalyzedEntityList(newAnalyzedEntityList);
  };

  useAnalyzedEntity({
    ids: idsForFetch,
    currentId,
    isListLoading: loading
  });

  useEffectAsync(async () => {
    if (filter.analyzedEntitiesIds.length) {
      return;
    }
    await getScans(filter);
  }, []);

  const redirectToEntity = (id: string): void => {
    setUrlParam('currentId', id);
  };

  useEffectAsync(async () => {
    if (!currentId && idsForFetch.length) {
      redirectToEntity(idsForFetch[0]); // use first entity from the list as default;
    }
  }, [currentId, idsForFetch]);

  useEffectAsync(() => {
    if (!analyzedEntity) {
      return;
    }

    const interpolationInOptions = interpolationOptions.find(
      (item) => item.value === interpolationTypeFromURL
    );

    if (interpolationTypeFromURL && !!interpolationInOptions) {
      setSelectedInterpolationId(interpolationInOptions.key);
      return;
    }

    const defaultInterpolation = 'y-amplitude-1_None';
    const defaultInterpolationInOptions = interpolationOptions.find(
      (item) => item.value === defaultInterpolation
    );

    if (defaultInterpolationInOptions) {
      setSelectedInterpolationId(defaultInterpolationInOptions.key);
      return;
    }

    setSelectedInterpolationId(interpolationOptions[0].key);
  }, [analyzedEntity?.id]);

  useEffect(() => {
    setIsFlagged(analyzedEntity?.flagged || false);
  }, [analyzedEntity?.flagged]);

  const goToNextAnalyzedEntity = (): void => {
    const currentIndex = ids.indexOf(currentId);
    if (currentId === undefined) return;
    let nextIndex = currentIndex + 1;
    if (nextIndex === ids.length) nextIndex = 0;
    const nextId = ids[nextIndex];
    redirectToEntity(nextId);
  };

  const goToPrevAnalyzedEntity = (): void => {
    const currentIndex = ids.indexOf(currentId);
    if (currentId === undefined) return;
    let prevIndex = currentIndex - 1;
    if (prevIndex < 0) prevIndex = ids.length - 1;
    const prevId = ids[prevIndex];
    redirectToEntity(prevId);
  };

  const updateStatus = (status: Status): void => {
    updateAnalyzedEntityById({
      variables: {
        updateAnalyzedEntityInput: {
          id: currentId,
          changed_by: user?.account?.username,
          status
        }
      }
    })
      .then((r) => {
        setStatusLocally(status);
        goToNextAnalyzedEntity();
      })
      .catch((e) => console.error('Error while updating analyzed entity status', e));
  };

  useEffect(() => {
    const interpolationInOptions = interpolationOptions.find(
      (item) => item.key === selectedInterpolationId
    );
    if (interpolationInOptions) {
      setUrlParam(InterpolationTypeUrlParamKey, interpolationInOptions.value);
    }
  }, [selectedInterpolationId]);

  const onClickPrevUtility = (): void => goToPrevAnalyzedEntity();
  const onClickNextUtility = (): void => goToNextAnalyzedEntity();

  const onApprove = (): void => updateStatus(Status.Approved);
  const onReject = (): void => updateStatus(Status.Rejected);

  const onFlag = (): void => {
    if (!analyzedEntity) {
      return;
    }
    updateAnalyzedEntityById({
      variables: {
        updateAnalyzedEntityInput: {
          id: currentId,
          flagged: !isFlagged,
          changed_by: user?.account?.username
        }
      }
    })
      .then(() => {
        setIsFlagged(!isFlagged);
        isFlagged ? setFlagged(flagged - 1) : setFlagged(flagged + 1);
      })
      .catch((e) => console.error('Error while updating analyzed entity flagged', e));
  };

  const onMouseOver = (): void => {
    if (!pinned) {
      setIsOpenMenu(true);
    }
  };
  const onMouseLeave = (): void => {
    if (!pinned) {
      setIsOpenMenu(false);
    }
  };
  const setNewCurrentId = (id: string): void => redirectToEntity(id);
  const otherUtilitiesData: OtherUtilityData[] = analyzedEntityList
    .map((item) => {
      return {
        id: item.id,
        analysisConfidence: item.analysis_confidence,
        geometry: item.geometry && JSON.parse(item.geometry.replaceAll("'", '"'))
      };
    })
    .filter((item) => item.id !== currentId);

  return (
    <PageContainer>
      <NavigationBar onMouseLeave={onMouseLeave} isOpen={isOpenMenu}>
        <NavigationBarContent
          onMouseOver={onMouseOver}
          isOpen={isOpenMenu}
          pinned={pinned}
          setPinned={setPinned}
          analyzedEntities={sortedAnalyzedEntities}
          emptyAnalyzedEntitiesIdList={emptyAnalyzedEntitiesIdList}
          setId={setNewCurrentId}
        />
      </NavigationBar>
      <Container>
        <Header
          onClickPrevUtility={useDebounceFunction(onClickPrevUtility, 300)}
          onClickNextUtility={useDebounceFunction(onClickNextUtility, 300)}
        />
        <InnerContainer>
          <Section>
            <MapView
              analyzedEntity={currentAnalyzedEntityt}
              analyzedEntityList={sortedAnalyzedEntities}
              interpolationType={selectedInterpolationId}
              isCrossingIndex={isCrossingIndex}
              isYPeaks={isYPeaks}
              isScanPathLayerOn={isScanPathLayerOn}
              isOrthophotoLayerOn={isOrthophotoLayerOn}
              isUtilitiesLayerOn={isUtilitiesLayerOn}
              isInterpolationLayerOn={!!selectedInterpolationId}
              isFixedScanPath={isFixedScanPath}
              otherUtilitiesData={otherUtilitiesData}
            />
            <MapContainer></MapContainer>
            <ControlButtons
              isYPeaks={isYPeaks}
              onChangeYPeaks={setIsYPeaks}
              isCrossingIndex={isCrossingIndex}
              onChangeCrossingIndex={setIsCrossingIndex}
              isOrthophotoLayerOn={isOrthophotoLayerOn}
              onChangeOrthophotoLayerOn={setIsOrthophotoLayerOn}
              isScanPathLayerOn={isScanPathLayerOn}
              onChangeScanPathLayerOn={setIsScanPathLayerOn}
              isFixedScanPath={isFixedScanPath}
              onChangeFixedScanPath={setIsFixedScanPath}
              isUtilitiesLayerOn={isUtilitiesLayerOn}
              onChangeUtilitiesLayerOn={setIsUtilitiesLayerOn}
              interpolationOptions={interpolationOptions}
              selectedInterpolationId={selectedInterpolationId}
              setSelectedInterpolationId={setSelectedInterpolationId}
              onApprove={onApprove}
              onReject={onReject}
              onFlag={onFlag}
            />
          </Section>
          <Section>
            <DetailsView analyzedEntityList={sortedAnalyzedEntities} />
          </Section>
        </InnerContainer>
      </Container>
    </PageContainer>
  );
};

export default Main;
